import React from 'react'
import '../styles/nav/TopMenu.css'
import ContactUsBtn from '../components/buttons/ContactUsBtn'
import NavBtn from '../components/buttons/NavBtn'
import SideMenu from './SideMenu'
import logo from '../assets/logo.png'
import { lit } from '../constants/literals'

export default function TopMenu(props) {
  const {lang = 'RU'} = props
  return (
    <div className='top-menu-container' id='top-menu'>
        <a href='#nav-link-1'>
        <div className='logo' style={{
          backgroundImage: `url(${logo})`
        }}></div>
        </a>
        <div className='btns-bar'>
            <NavBtn title={lit?.[lang]?.main} href='#nav-link-1'/>
            <NavBtn title={lit?.[lang]?.about} href='#nav-link-2'/>
            <NavBtn title={lit?.[lang]?.equipment} href='#nav-link-3'/>
            <NavBtn title={lit?.[lang]?.services} href='#nav-link-4'/>
            <NavBtn title={lit?.[lang]?.contacts} href='#nav-link-6'/>
        </div>
        <div className='right-section'>
            <ContactUsBtn lang={lang}/>
            <SideMenu lang={lang}/>
        </div>
    </div>
  )
}
