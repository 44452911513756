import React from 'react'
import '../styles/components/ServicesBlock.css'
import DescriptedImage from './DescriptedImage'
import Image5 from '../assets/Image5.png'
import { lit } from '../constants/literals'

export default function ServicesBlock(props) {
  const {lang = 'RU'} = props
  return (
    <div className='services-block-container' id='nav-link-4'>
        <DescriptedImage
            title={lit?.[lang]?.title7}
            description={lit?.[lang]?.desc5}
            imgUrl={Image5}
        />
    </div>
  )
}
