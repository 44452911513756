import React from 'react'
import '../styles/components/AboutBlock.css'
import DescriptedImage from './DescriptedImage'
import Image1 from '../assets/Image1.png'
import { lit } from '../constants/literals'

export default function AboutBlock(props) {
  const { lang = 'RU' } = props
  return (
    <div
      className='about-block-container'
      id='nav-link-2'>
        <DescriptedImage
          title={lit?.[lang]?.title2}
          description={lit?.[lang]?.desc1}
          imgUrl={Image1}
        />
    </div>
  )
}
