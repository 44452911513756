import React from 'react'
import '../styles/components/MapsBlock.css'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import IconTitledCard from './IconTitledCard';
import PhoneIcon from '../icons/PhoneIcon';
import LetterIcon from '../icons/LetterIcon';
import LocationIcon from '../icons/LocationIcon';
import { lit } from '../constants/literals';

export default function MapsBlock(props) {
    const {lang = 'RU'} = props
  return (
    <div className='maps-block-container' id='nav-link-6'>
        <YMaps>
            <Map
                defaultState={{ center: [59.847510, 30.505712], zoom: 10 }}
                style={{
                    display: 'flex',
                    flex: '1',
                    width: '100%',
                    height: '70vh'
                }}
            >
                 <Placemark
                    geometry={[59.847510, 30.505712]}
                />
            </Map>
        </YMaps>

        <div className='cards-row'>
            <IconTitledCard title={lit?.[lang]?.contacts_data} description={lit?.[lang]?.data1} icon={<PhoneIcon color='var(--primary-color)' sizeRem={7}/>}/>
            <IconTitledCard title={lit?.[lang]?.urid_data} description={lit?.[lang]?.data2} icon={<LetterIcon color='var(--primary-color)' sizeRem={7}/>}/>
            <IconTitledCard title={lit?.[lang]?.adress} description={lit?.[lang]?.data3} icon={<LocationIcon color='var(--primary-color)' sizeRem={7}/>}/>
        </div>
    </div>
  )
}
