export const lit = {
    'RU': {
            title1: 'Royal Kepler Robots',
            subtitle1: 'Крупногабаритная FGF 3D печать, изготовление оснастки из полимерных материалов и изделий из композитов',
            contactUs: 'Заказать звонок',

            main: 'Главная',
            about: 'О компании',
            equipment: 'оборудование',
            services: 'услуги',
            contacts: 'контакты',

            title2: 'О компании',
            title3: 'Производственная база',
            title4: 'Промышленный многоосевой принтер на базе робота',
            title5: 'Комплекс черновой фрезерной обработки на базе робота',
            title6: '5 координатный обрабатывающий центр',
            title7: 'Услуги нашей компанией',

            desc1: 'АО "РКР" компания полного производственного цикла с передовым техническим оснащением собственной разработки, высоко квалифицированным штатом персонала и внедренной системой менеджмента качества по авиационным стандартам. Предприятие занимается разработкой и выпуском конечного продукта путем 3D печати формообразующей оснастки для любого сектора промышленности, где применяется оснастка для производства деталей и конструктивных элементов. Для открытого рынка разрабатываем, производим и продаем готовые комплексы для крупноформатной 3D печати полимерными материалами. В основе работы нашего комплекса лежит собственная разработка программного обеспечения, которое позволяет достигать стабильности получаемых изделий.',
            desc2: 'Большой промышленный многоосевой принтер на базе робота, производительность экструдера более 15кг/час для крупногабаритной 3D печати. Данная модель будет превосходным решением для промышленного предприятия. Промышленный 3D-принтер, осуществляет печать по технологии FJF — послойным наращиванием композитного материала. Область построения 6000х3000х2000 мм обеспечивает печать форм крупногабаритных деталей. Позволяет произвести форму в течении суток без дополнительных затрат. Конструкция принтера и принятые проектные решения обеспечивают надежность, точность, быстроту печати, открывают возможности производства форм любой степени сложности (искривленная конфигурация, внутренние полости, большое количество уникальных сечений в различных плоскостях).',
            desc3: 'Технология крупногабаритной FGF печати предполагает наличие большого припуска при создании заготовки, увеличение общей производиельности комплекса достигается за счет разделения черновой и чистовой обработки на разное оборудование. Для черновой обработки используется промышленный робот с высокопроизводительным шпинделем и рабочей зоной равной FGF принтеру. Выполняет первоначальную обработку и подготовку изделия к чистовой обработке.',
            desc4: 'Используемый нами портальный конструкция фрезерный станок с зоной обработки 6000мм x 1800мм x 1200мм накладывает ограничений на максимальный размер построения модели, и требует дополнительную конструкторскую и технологическую подготовку производства. Но добавляет возможность достижения высоких показателей геометрической точности поверхностей в рамках 8 квалитета и шероховатость поверхности вплоть до Ra 0.8, обеспечивая высокое качество готовой продукции.',
            desc5: <ul>
                        <li>Производство оснастки по "КД" заказчика в необходимых количествах.</li>
                        <li>Полная интеграция технологического комплекса аналогичного нашему на предприятии заказчика, которая включает в себя: <br/>
                            <ul>
                                <li>Расчет стоимости ячейки 3D печати под мощности, параметры и задачи заказчика.</li>
                                <li>Доставка и монтаж оборудования в цеховые параметры.</li>
                                <li>Программно-техническое сопровождение наших программистов в течение 60 дней после запуска комплекса</li>
                                <li>Проверенные поставщики материала с прямыми контактами</li>
                            </ul>
                        </li>
                    </ul>,
            name: 'Ваше имя',
            name_placeholder:  'Введите имя',
            email: 'Ваш E-mail',
            email_placeholder: 'Введите e-mail',
            phone: 'Ваш телефон',
            phone_placeholder: '+7 (ХХХ) ХХХ - ХХ - ХХ',
            messageTitle: 'Тема сообщения',
            messageTitle_placeholder: 'Тема сообщения',
            messageText: 'Ваше сообщение',

            send_message: 'Отправить',
            feedback: 'Обратная связь',
            contacts_data: 'Контактны данные',
            urid_data: 'Юридические данные',
            adress: 'Наш адресс',
            data1: <div>+7 (495) 728-46-06<br/>aks@rkrobots.ru</div>,
            data2: <div>ИНН / КПП 7814825740 / 780701001 <br/>ОГРН 1237800088240 </div>,
            data3: <div>198323, город Санкт-Петербург,<br/>ул Волхонское ш, д. 115 к. 4 литера А, помещ. 1-н помещ. 38</div>,

            footer_text: '© 2024 АО РКР - Изготовление оснастки и готовых изделий из композитного материала для промышленных предприятий',
            message_success_send: 'Сообщение отправлено',
            message_failed_send: 'Не удалось отправить сообщение'
    },

    'EN': {
            title1: 'Royal Kepler Robots',
            subtitle1: 'Large Scale FGF 3D printing, manufacturing of equipment from polymer materials for composite products',
            contactUs: 'Request a call',
        
            main: 'Home',
            about: 'Company',
            equipment: 'Solutions ',
            services: 'Servicies',
            contacts: 'Contact',
        
            title2: 'Company',
            title3: 'Facility equipment',
            title4: 'Large scale robotic FGF 3D printer',
            title5: 'Robotic system for rough milling',
            title6: 'Multi axis milling machine',
            title7: 'Our servicies',
        
            desc1: 'Royal Kepler Robots is a full-cycle production company with advanced technical equipment of its own design, highly qualified staff and an implemented quality management system according to aviation standards. The enterprise is engaged in the development and production of the final product through 3D printing of tools, jigs and molds for any industrial sector, where such equipment is used. For the customers we develop, produce and sell complete facilities for large-scale 3D printing with polymer materials. The basis of our complex operation is our own software development, which ensures the stability of the products obtained.',
            desc2: 'Heavy industrial robot-based printer with extruder productivity  of over 15kg/hour for large-scale 3D printing. This model will be an excellent solution for an industrial enterprise. The industrial 3D printer operates  using FJF technology - layer-by-layer construction of composite polymers. The construction area of 6000x3000x2000 mm ensures printing of forms of large-sized parts. Allows you to produce a mold within 24 hours without additional costs. The design of the printer and the adopted design solutions ensure reliability, accuracy, speed of printing, and open up the possibility of producing forms of any degree of complexity (curved configuration, internal cavities, a large number of unique sections in various planes).',
            desc3: 'The large-scale FGF printing technology implies the presence of a large allowance when creating a workpiece, and increasing in the overall productivity of the complex is achieved by separating rough and finishing machining into different equipment. For rough machining , an industrial robot with a high-performance spindle and a working area equal to an FGF printer is used. It performs initial processing and preparation of the product for finish machining.',
            desc4: 'Ordinary large multi axis milling machines with a processing area of 6000mm x 1800mm x 1200mm imposes limitations on the maximum size of the workpiece, and requires additional engineering and technological preparation for production. However, it adds the capability to achieve geometric accuracy within the highest quality standards and surface roughness down to Ra 0.8, ensuring high-quality of the finished product.',
            desc5: <div>● Production of tooling according to the customer's technical specifications in the required quantities.<br/><br/>● Full installation of a technological complex similar to ours at the customer’s facility, which includes: <br/> - Calculation of the cost of a 3D printing cell based on the customer's capacity, parameters, and task. <br/>  - Delivery and installation of equipment to the workshop specifications. <br/>- Software and technical support by our programmers within 60 days after the complex is launched <br/>- Verified material suppliers with direct contacts in Russia</div>,
        
        
        
            name: 'Your Name',
            name_placeholder: 'Text a Name',
            email: 'Your E-mail',
            email_placeholder: 'Text an e-mail',
            phone: 'Your Phone number',
            phone_placeholder: '+x (ХХХ) ХХХ - ХХ - ХХ',
            messageTitle: 'Request subject',
            messageTitle_placeholder: 'Text a subject',
            messageText: 'Your request',
        
            send_message: 'Send',
            feedback: 'Feedback',
            contacts_data: 'Contacts',
            urid_data: 'Legal address',
            adress: 'Facility adress',
            data1: <div>+7 (495) 728-46-06<br/>aks@rkrobots.ru</div>,
            data2: <div>ИНН / КПП 7814825740 / 780701001 <br/>ОГРН 1237800088240 </div>,
            data3: <div>198323, St. Petersburg,<br/>ул Волхонское ш, д. 115 к. 4 литера А, помещ. 1-н помещ. 38</div>,
        
            footer_text: '© 2024 АО РКР - Large Scale FGF 3D printing, manufacturing of equipment from polymer materials for composite products'
       }
}