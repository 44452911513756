import React from 'react'
import '../styles/components/PresentBlock.css'
import { lit } from '../constants/literals'
import PropTypes from 'prop-types'

export default function PresentBlock(props) {
  const {imgUrl, lang = 'RU'} = props
  return (
    <div
      className='present-block'
      id='nav-link-1'
    >
      <div className='bg' style={{ backgroundImage: `url(${imgUrl || ''})`}} />
      <h1 className='title-1'>{lit?.[lang]?.title1.toUpperCase()}</h1>
      <h3 className='subtitle-1'>{lit?.[lang]?.subtitle1.toUpperCase()}</h3>
    </div>
  )
}

PresentBlock.propTypes = {
  imgUrl: PropTypes.any
}